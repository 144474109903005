import React, { memo, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ViewAll from '../../components/ViewAll/ViewAll';
import { useSectionTimeSpent } from '../../util';
import UpdateGrid from '../UpdateGrid/UpdateGrid';

import './NetworkUpdatesPreview.scss';

function NetworkUpdatesPreview({ data: { id, name, title, backgroundColor = 'grey' } }) {
  const containerRef = useRef(null);

  useSectionTimeSpent(name, containerRef);

  const { allWpUpdate } = useStaticQuery(graphql`
    {
      allWpUpdate(
        filter: { updateTags: { nodes: { elemMatch: { slug: { eq: "networks" } } } } }
        sort: { order: DESC, fields: dateGmt }
        limit: 3
      ) {
        nodes {
          ...UpdatePreviewContent
        }
        totalCount
      }
    }
  `);

  return (
    <div id={id} className="NetworkUpdatesPreview">
      <h2 className="title">{title}</h2>
      <UpdateGrid updateNodes={allWpUpdate} backgroundColor={backgroundColor} />
      {allWpUpdate?.totalCount > 3 && <ViewAll link="/updates-tag/networks/" alignRight />}
    </div>
  );
}

export default memo(NetworkUpdatesPreview);

export const fragments = graphql`
  fragment NetworkUpdatesPreview on WpPage_Flexiblelayout_FlexibleChildren_NetworkUpdatesPreview {
    id
    jumpToLinkTitle
    name
    title
    backgroundColor
  }

  fragment NetworkUpdatesPreview_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_NetworkUpdatesPreview {
    id
    jumpToLinkTitle
    name
    title
    backgroundColor
  }
`;
